<template>
	<div class="layer-popup__background" v-if="layerPopup.popupActived">
		<div class="layer-popup">
			<h2>{{ layerPopup.title }}</h2>
			<p v-html="layerPopup.text"></p>
			<div
				class="layer-popup__button-wrapper yes-or-no-select"
				v-if="layerPopup.type === 'yesOrNoSelect'"
			>
				<button class="purple-border" @click="executeCancelEvent">
					{{ layerPopup.buttonCancelText }}
				</button>
				<button class="purple-background" :class="layerPopup.buttonConfirmClass" @click="executeConfirmEvent">
					{{ layerPopup.buttonConfirmText }}
				</button>
			</div>
			<div
				class="layer-popup__button-wrapper"
				v-else-if="layerPopup.type === 'yesOnly'"
			>
				<button
					ref="layerPopupElement"
					class="purple-border purple-background"
					@click="executeConfirmEvent"
				>
					{{ layerPopup.buttonConfirmText }}
				</button>
			</div>
			<div
				class="layer-popup__loading"
				v-else-if="layerPopup.type === 'waiting'"
			>
				<Vue3Lottie :animationData="loadingShape" />
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { Vue3Lottie } from "vue3-lottie";
import loadingShape from "@/assets/lottie/loading_0402";
const store = useStore();
const layerPopup = ref(
	computed(() => store.getters["resultCodeProcessor/getSelectedPopup"])
);
const executeConfirmEvent = () => {
	store.commit("resultCodeProcessor/executeConfirmEvent");
	store.commit("resultCodeProcessor/setActived", false);
};
const executeCancelEvent = () => {
	store.commit("resultCodeProcessor/executeCancelEvent");
	store.commit("resultCodeProcessor/setActived", false);
};

const layerPopupElement = ref(null);

const blurAllElement = () => {
	const focusableElements = document.querySelectorAll(
		'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
	);
	focusableElements.forEach((element) => {
		element.blur();
	});
};

const keydownEvent = (e) => {
	if (layerPopup.value.popupActived) {
		blurAllElement();
	}
	const validKeys = ["Enter", "Escape"];
	if (layerPopup.value.type === "waiting" || !validKeys.includes(e.key)) {
		return;
	}
	const eventObj = { Enter: executeConfirmEvent, Escape: executeCancelEvent };
	if (layerPopup.value.type === "yesOnly") {
		eventObj.Escape = executeConfirmEvent;
	}
	eventObj[e.key]();
};

watch(layerPopup, (val) => {
	if (val.popupActived) {
		window.addEventListener("keydown", keydownEvent);
	} else if (!val.popupActived) {
		window.removeEventListener("keydown", keydownEvent);
	}
});

// onMounted(() => {});

// onBeforeUnmount(() => {});
</script>

<style scoped>
.layer-popup__background {
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 100;
}

.layer-popup {
	width: calc(100% - 20px);
	max-width: 420px;
	min-height: 280px;
	border-radius: 8px;
	background: #fff;
	margin: 0 auto;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.layer-popup h2 {
	width: 100%;
	height: 50px;
	border-bottom: 1px solid #d8d8d8;
	line-height: 48px;
	font-size: 18px;
	font-weight: bold;
	letter-spacing: -0.9px;
	text-align: center;
	color: #17191d;
}

.layer-popup p {
	width: 100%;
	margin-top: 50px;
	text-align: center;
	font-size: 16px;
	letter-spacing: -0.8px;
	padding:0 10%;
}

.layer-popup__button-wrapper {
	width: 100%;
	margin: 0 auto;
	position: absolute;
	bottom: 30px;
}
.layer-popup__loading {
	position: relative;
	width: 24px;
	height: 24px;
	margin: 24px auto 0;
	overflow: hidden;
}
.layer-popup__loading .lottie-animation-container {
	position: absolute;
	width: 110px;
	height: 110px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.layer-popup__button-wrapper.yes-or-no-select {
	display: flex;
	gap: 10px;
	justify-content: center;
}

.layer-popup__button-wrapper.yes-or-no-select button {
	width: 140px;
	margin: 0;
	font-size: 18px;
	letter-spacing: -0.9px;
}

.layer-popup__button-wrapper button {
	width: 214px;
	font-size: 18px;
	letter-spacing: -0.9px;
}
</style>