/* 	
	resultCodeOptions 에 정의된 명세 중 resultCD가 일치하는 명세를 selectedPopup 에 업데이트 합니다.
	resultCodeOptions[resultCD].popup: <boolean> true 면 팝업을 띄우고, false일 경우 팝업을 띄우지 않고 이벤트 발생
	selectedPopup.type : <string> 'yesOnly' (버튼 하나만 있는 경우) | 'yesOrNoSelect' (긍정 부정 (예, 아니오 등) 버튼 2개) | 'waiting' 로딩 전용. 버튼 없음   
 	selectedPopup.title : <string> 기본값: '알림', 팝업 제목 
	selectedPopup.text : <string> 팝업 내용
	selectedPopup.buttonConfirmText : <string> 긍정반응 버튼이름
	selectedPopup.buttoncancelText : <string> 부정반응 버튼이름
	selectedPopup.buttonConfirmClass : <string> 긍정반응 버튼 추가 클래스 (구글 태그매니저 감지할때 사용)
	selectedPopup.popupActived : <boolean> 팝업 열기 / 닫기 
	selectedPopup.scrollRelease : <string | null>  'no' 스크롤 잠금 해제 안됨 | 'afterConfirm' 확인 버튼 클릭 후 스크롤 잠금 해제 | null 확인 버튼 클릭 후 스크롤 
	resultCodeOptions.payloadConfirmEvent: 커스텀이벤트를 정의하고 사용할 수 있습니다.
	resultCodeOptions.confirmEvent, resultCodeOptions.cancelEvent 명시되어 있지 않으면 팝업 닫힘
*/
import resultCodeOptions from '@/util/resultCodeOptions'
import {blockBodyScrollEvents, releaseBodyScroll} from '@/util/global'

export const resultCodeProcessor = {
	namespaced:true, 
	state:{
		isAdditionalPopupOn:false,
		selectedPopup:{
			popupActived:false,
			type:'yesOnly',
			title:'알림',
			text:'',
			nextEvent:null,
			buttonConfirmText:'확인',
			buttonCancelText:'취소',
			buttonConfirmClass:null,
			scrollRelease:null
		},
		redirectionURL:'',
	},
	mutations:{
		setActived(state, booleanVal){
			state.selectedPopup.popupActived = booleanVal
		}, 
		executeConfirmEvent(state){
			const scrollRelease = state.selectedPopup.scrollRelease
			const confirmEvent = resultCodeOptions.payloadConfirmEvent || resultCodeOptions.optionsByCode[state.selectedPopup.code]?.confirmEvent // 코드별 설정된 확인 이벤트
			confirmEvent ? confirmEvent() : undefined
			if(scrollRelease === 'no'){
				state.selectedPopup.popupActived = false
				state.selectedPopup.scrollRelease = null
				return 
			}
			state.selectedPopup.popupActived = false
			state.selectedPopup.scrollRelease = null
			releaseBodyScroll()
		},
		executeCancelEvent(state){
			const scrollRelease = state.selectedPopup.scrollRelease
			if(scrollRelease === 'no'){
				state.selectedPopup.popupActived = false
				state.selectedPopup.scrollRelease = null
				return 
			}
			if(state.selectedPopup.cancelEvent){
				state.selectedPopup.cancelEvent()
				state.selectedPopup.popupActived = false
			}
			else{
				state.selectedPopup.popupActived = false
			}
			releaseBodyScroll()
		},
		setIsAdditionalPopupOn(state){
			state.isAdditionalPopupOn = !state.isAdditionalPopupOn
		},
		setRedirect(state, url){
			state.redirectionURL = url
		},
		initSelectedPopup(state){
			resultCodeOptions.payloadConfirmEvent = null
			state.selectedPopup.type = 'yesOnly'
			state.selectedPopup.popupActived = false
			state.selectedPopup.title ='알림'
			state.selectedPopup.text = ''
			state.selectedPopup.nextEvent = null
			state.selectedPopup.buttonConfirmText = '확인'
			state.selectedPopup.buttonCancelText ='취소'
			state.selectedPopup.buttonConfirmClass = null
			state.selectedPopup.scrollRelease = null
		},
	},
	actions:{
		setSelectedPopupByCode({state, commit}, payload){
			//팝업 초기화
			commit('initSelectedPopup')
			try{
				//payload 는 객체 (data) 아니면 code 로 받습니다.
				const payloadCode = payload?.code ? payload.code : payload
				const popupActived = resultCodeOptions.optionsByCode[payloadCode].popup
				state.selectedPopup.popupActived = popupActived
				state.selectedPopup.code = payloadCode
				if(!state.selectedPopup.code || resultCodeOptions.optionsByCode[payloadCode] === undefined ){
					blockBodyScrollEvents()
					state.selectedPopup.text = '알 수 없는 에러입니다. 새로고침 또는 확인 버튼을 눌러 주세요.'
					resultCodeOptions.payloadConfirmEvent = ()=>{window.open('/', '_self')}
					return
				}
				Object.assign(state.selectedPopup, resultCodeOptions.optionsByCode[payloadCode])
				if(state.selectedPopup.popupActived){
					blockBodyScrollEvents()
					return
				}
				else if(!state.selectedPopup.popupActived){
					throw 'executeConfirmEvent'
				}
			}
			catch (mutationName){ // 팝업이 필요없는 경우 이벤트를 바로 실행합니다.
				commit(mutationName);
			}
		},
		setSelectedPopupByPayload({state, commit}, payload){
			//팝업 초기화
			commit('initSelectedPopup')
			state.selectedPopup.popupActived = true
			Object.assign(state.selectedPopup, payload)
			resultCodeOptions.payloadConfirmEvent = payload.payloadConfirmEvent || null
			if(state.selectedPopup.type === 'waiting' || payload.scrollRelease === 'no' || payload.scrollRelease === 'afterConfirm' ){
				return 
			}
			blockBodyScrollEvents()
		},
	},
	getters:{ 
		getSelectedPopup(state){
			return {
				popupActived:state.selectedPopup.popupActived,
				title:state.selectedPopup.title,
				text:state.selectedPopup.text,
				type:state.selectedPopup.type,
				buttonConfirmText:state.selectedPopup.buttonConfirmText,
				buttonCancelText:state.selectedPopup.buttonCancelText,
				buttonConfirmClass:state.selectedPopup.buttonConfirmClass,
			}
		},
		getIsAdditionalPopupOn(state){
			return state.isAdditionalPopupOn
		}
	}
}