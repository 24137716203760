import axios from 'axios'
const apiURL = process.env.VUE_APP_API 
export const accountPage = {
	namespaced:true, 
	state:{
		loginSessionResult:'',
		agreements:{},
		agreementDetail:{
			active:false,
			title:'',
			content:''
		},
		isTermsDetailActived:false,
		currentProcess:'adultCheck',
		certifyProcess:false,
		userData:{}
	},
	mutations:{
		setAgreements(state, payload){
			payload.forEach(item => {
				item.checked = false
				item.titleForUI = item.title.indexOf('_') < 0 ? item.title : item.title.slice(0, item.title.indexOf('_'))
			})
			state.agreements = payload
		},
		setAgreementDetail(state, payload){
			state.agreementDetail = payload
		},
		toggleAgreementCheck(state, index){
			state.agreements[index].checked = !state.agreements[index].checked
		},
		setAgreementCheckAll(state, boolean){
			state.agreements.forEach(item =>{
				if(item.ismust === 1){
					item.checked = boolean
				}
			})
		},
		setCertifyProcess(state, boolean){
			state.certifyProcess = boolean
		},
		setCurrentProcess(state, process){
			state.currentProcess = process
		},
		setUserData(state, payload){
			state.userData = payload
		},
		setLoginSessionResult(state, code){
			state.loginSessionResult = code
		}
	},
	actions:{
		async getAgreements({commit, dispatch}){
			try{
				const agreements = await axios.get(`${apiURL}/api/v1/user/agreements?setid=1`)
				if(agreements.data.code !== 'S0000'){
					throw agreements.data.code
				}
				commit('setAgreements', agreements.data.data)
			}
			catch(code){
				dispatch("resultCodeProcessor/setSelectedPopupByCode", code, { root: true,});
			}
		},
		async updateSNSAgree({commit, dispatch}, payload){
			try{
				const response = await axios.post(`${apiURL}/api/v1/snsagree`, payload)
				if(response.data.code !== 'S0000'){
					throw response.data.code
				}
				commit('setLoginSessionResult', response.data.code)
			}
			catch(code){
				if(code === 'ES5000'){
					dispatch('resultCodeProcessor/setSelectedPopupByPayload', {
						type: 'yesOnly',
						text: '인증시간이 만료 되었습니다. 다시 시도해 주세요.',
						payloadConfirmEvent(){window.open('/account', '_self')}
					}, { root: true,})
				}
				else{
					dispatch("resultCodeProcessor/setSelectedPopupByCode", code, { root: true,});
				}
			}
		}
	},
	getters:{
		getAgreements(state){
			return state.agreements
		},
		getAgreementDetail(state){
			return state.agreementDetail
		},
		getCurrentProcess(state){
			return state.currentProcess
		},
		getCertifyProcess(state){
			return state.certifyProcess
		},
		getUserData(state){
			return state.userData
		},
		getLoginSessionResult(state){
			return state.loginSessionResult
		}
	}
}