import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import accountPageRoutes from "./pages/accountPageRoutes";
import shopPageRoutes from "./pages/shopPageRoutes";
import myPageRoutes from "./pages/myPageRoutes";

const routes = [
    {
        // 서비스 소개
        path: "/",
        name: "Home",
        component: () => import("@/components/serviceIntroducePage"), // import("@/components/homePage"),
        meta: {
            title: "GameBridge | 게임브릿지",
        },
    },
    {
        // 솔루션 소개
        path: "/solution",
        name: "Solution",
        component: () => import("@/components/solutionIntroducePage"), // import("@/components/homePage"),
        meta: {
            title: "비즈니스소개",
        },
    },
    ...accountPageRoutes, // 계정
    ...shopPageRoutes, //샵
    ...myPageRoutes, // 마이페이지
    {
        path: "/terms",
        name: "Terms",
        component: () => import("@/components/termsPage"),
    },
	{
		path:'/errorPage/page',
		name:'ErrorPage',
		component: () => import("@/components/errorPage"),
	},
    { path: "/:pathMatch(.*)*", redirect: { name: "Home" }},
];

// 상품목록, 구매내역, 문의내역의 경우 스크롤 위치 저장
const router = new createRouter({
    history: createWebHistory(),
    linkExactActiveClass: "active",
    routes,
    scrollBehavior(from, to, savedPosition) {
        const saveScrollPos = () => {
            store.commit("setSavedScrollPosition", {
                conditional: true,
                ...savedPosition,
            });
        };

        if (
            savedPosition &&
            from.name === "ShopPage" &&
            to.name === "ShopDetail"
        ) {
            saveScrollPos();
        } else if (
            savedPosition &&
            from.name === "History" &&
            to.name === "HistoryDetail"
        ) {
            saveScrollPos();
        } else if (
            savedPosition &&
            from.name === "Inquiry" &&
            to.name === "InquiryDetail"
        ) {
            saveScrollPos();
        } else {
            store.commit("setSavedScrollPosition", {
                conditional: false,
                left: 0,
                top: 0,
            });
            return { left: 0, top: 0 };
        }
    },
});
// router.beforeEach((to, from, next) =>{
    
//     next()
// })
router.afterEach((to) => {
    const title = to.meta.title === undefined ? "GameBridge | 게임브릿지" : to.meta.title;
    document.title = title;
});
export default router;
