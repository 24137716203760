<template>
	<div class="shop-inspecting">
		<div class="shop-inspecting__wrapper">
			<img src="../assets/img/logo-new@2x.png" alt="logo">
			<h1><span>게임브릿지</span> 샵 정기점검중 입니다.</h1>	
			<p>
				보다 안정적인 서비스 환경을 만들기 위해
				<span>현재 <strong>서비스</strong>를 <strong>점검 중</strong> 입니다.</span>
				<span>이용에 불편을 드린 점 진심으로 사과드리며,</span> 
				<span>예정된 시간 안에 점검을 마칠 수 있도록</span> 
				<span>최선을 다하겠습니다.</span>
			</p>
			<ul>
				<li>점검 일자: 2025.01.22 (수) 11:00 ~ 16:00</li>
				<li>점검 사유: 정기점검</li>
			</ul>
		</div>
	</div>
</template>

<script setup>

</script>

<style scoped>
	.shop-inspecting{
		position: relative;
		padding:114px 10px 80px;
	}
	.shop-inspecting__wrapper{
		max-width:700px;
		width:calc(100% - 20px);
		padding:40px 10px 30px;
		background:#fff;
		border-radius:24px;
		margin:0 auto;
		border:1px solid #d8d8d8;
	}
	.shop-inspecting__wrapper img{
		display:block;
		margin:0 auto;
		width:120px;
		border-radius: 12px;
	}
	.shop-inspecting__wrapper h1{
		margin-top:24px;
		text-align: center;
		font-size: 18px;
		font-weight: bold;
		letter-spacing: -0.9px;
		color: #17191d;
	}
	.shop-inspecting__wrapper h1 span{
		color: #1074f3;
	}
	.shop-inspecting__wrapper p{
		margin-top:24px;
		text-align: center;
		font-size: 14px;
		line-height: 1.29;
		letter-spacing: -0.7px;
		color: #17191d;
	}
	.shop-inspecting__wrapper p span{
		display:block;
	}
	.shop-inspecting__wrapper ul{
		max-width:420px; 
		width:100%;
		margin:60px auto 30px;
		padding: 24px 12px;
		border-radius: 8px;
		background-color: #f8f9fa;
	}
	.shop-inspecting__wrapper ul li{
		font-size:14px;
	}
	.shop-inspecting__wrapper a{
		display:block; 
		max-width:294px; 
		width:100%;
		height:50px;
		margin:0 auto; 
		background:#1074f3;
		color:#fff;
		font-size:16px;
		letter-spacing: -0.8px;
		text-align: center;
		line-height:50px;
		border-radius:26px;
		
	}
	@media screen and (min-width:430px){
		.shop-inspecting{
			position: relative;
			padding:114px 24px 80px;
		}
		.shop-inspecting__wrapper{
			max-width:700px;
			width:100%;
			padding:40px 24px 30px;
		}
	}
	@media screen and (min-width:760px){
		.shop-inspecting{
			padding:114px 0px 50px; 
		}
		.shop-inspecting__wrapper{
			padding:54px 0;
		}
		.shop-inspecting__wrapper img{
			width:240px;
		}
		.shop-inspecting__wrapper h1{
			margin-top:40px;
			font-size:24px;
		}
		.shop-inspecting__wrapper p{
			font-size:18px;
		}
		.shop-inspecting__wrapper ul li{
			font-size:18px;
		}
	}
	@media screen and (min-width:1200px){
		.shop-inspecting{
			padding:114px 0px 50px; 
		}
	}
</style>