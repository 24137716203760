'use strict'

//날짜 체크
const formatDate = (jsonDate, locale = 'ko-KR') => {
	const dateObject = new Date(jsonDate);
	const options = { month: '2-digit', day: '2-digit' };
	const combinedString = dateObject.toLocaleDateString(locale, options)
	const stringLastIndex = combinedString.length - 1
	return combinedString.slice(0, stringLastIndex);
}
//팝업 중앙정렬 
const popupAlignedCenter = (url, title, w, h) => {
	const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
	const dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;
	const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
	const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
	const left = ((width / 2) - (w / 2)) + dualScreenLeft;
	const top = ((height / 2) - (h / 2)) + dualScreenTop;
	window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
}

//파일 업로드 관련

/** 
 * payload.target: 파일 엘리먼트 
 * payload.previousFileArray: 파일을 새로 업데이트 하기 전에 저장한 배열
 * payload.maxLength:파일 최대 업로드 가능한 개수 
 * payload.maxFileSize:파일당 최대크기  
*/
const getDataFiles = (payload) =>{
	const targetElement = payload.target
	const previousFileArray = payload.previousFileArray
	const maxLength = payload.maxLength
	const maxFileSize = payload.maxFileSize
	const fileStatusInfo = {
		containerElements:'',
		hasInvalidFiles:false,
		isMaxFileLengthExceeded:false, //최대 파일 개수를 넘었는지
	}
	const dataTransfer = new DataTransfer(); //파일 업로드 -> 이전 파일이 있으면 추가 ->
	const initialFiles = targetElement.files; // 현재 업로드하는 파일 객체
	const initialFileArray = Array.from(initialFiles); // files를 배열로 변환

	const filteredFiles = [] //용량 체크로 걸러진 파일
	const totalArray = [] // 이전 + 현재 선택된 파일의 종합 배열

	if(previousFileArray.length > 0){ //previousFileList가 1개 이상 있으면 총 배열 fileArray에 저장
		for(let i = 0; i<previousFileArray.length; i++){
			totalArray.push(previousFileArray[i])
		}
	}
	
	const getMBSize = (size) => {//용량 메가바이트로 환산 
		const MB = size / 1024**2
		return MB.toFixed(1)
	};
	const checkFileSizeExceededMax = (file) =>{ // 용량 넘어가는 파일 필터링
		const MBSize = getMBSize(file.size)
		const isExceeded = MBSize > maxFileSize
		return isExceeded
	}
	const checkHasNonImageFiles = (file)=>{
		return !file.type.includes('image/')
	}
	const filterByFileSizeAndType = () =>{
		for(let i = 0; i<initialFileArray.length; i++){
			const isFileTypeNonImage = checkHasNonImageFiles(initialFileArray[i])
			const isFileSizeMaxExceeded = checkFileSizeExceededMax(initialFileArray[i])
			if(isFileTypeNonImage || isFileSizeMaxExceeded){
				fileStatusInfo.hasInvalidFiles = true
				continue
			}
			else{
				filteredFiles.push(initialFileArray[i])
			}
		}
	}
	//이전 파일 개수 + 용량 체크 끝난 파일 개수가 업로드 최대치를 넘기면, 최대치를 넘긴 만큼 잘라냅니다.
	const sliceSizeFilteredFiles = () =>{
		const slicedLimit = maxLength - previousFileArray.length // 최대 업로드 가능 파일 수 - 이전에 남아있는 파일 수
		const slicedArray = filteredFiles.slice(0, slicedLimit) // 용량 기준에 부합하는 파일 리스트에서 위 차이만큼 잘라냅니다. 
		fileStatusInfo.isMaxFileLengthExceeded = true
		return slicedArray
	}
	const getTotalArray = () =>{ //최종 업데이트용 파일 환산
		if(previousFileArray.length + filteredFiles.length > maxLength){
			const slicedFiles = sliceSizeFilteredFiles()
			for(let i = 0; i<slicedFiles.length; i++){
				totalArray.push(slicedFiles[i])
			}
		}
		else{
			for(let i = 0; i<filteredFiles.length; i++){
				totalArray.push(filteredFiles[i])
			}	
		}
	}
	const updateFiles = () =>{
		for(let i = 0; i<totalArray.length; i++){
			dataTransfer.items.add(totalArray[i])
		}
		targetElement.files = dataTransfer.files // 파일 태그의 리스트를 현재 업로드하는 파일 리스트로 변경
		return dataTransfer.files 
	}
	filterByFileSizeAndType()
	getTotalArray()
	updateFiles()
	return {
		files:dataTransfer.files,
		containerElements:fileStatusInfo.containerElements,
		hasInvalidFiles:fileStatusInfo.hasInvalidFiles,
		isMaxFileLengthExceeded:fileStatusInfo.isMaxFileLengthExceeded, //최대 파일 개수를 넘었는지
	}
}
/** 
 * payload.index: 지울 파일의 index
 * payload.totalFiles: 업로드 과정에서 업데이트된 이전 파일 배열
*/
const getRemovedFileResult = (payload) =>{
	const dataTransfer = new DataTransfer();
	const totalFileList  = Array.from(payload.totalFiles)
	totalFileList.splice(payload.index, 1)
	for(let j = 0; j<totalFileList.length; j++){
		dataTransfer.items.add(totalFileList[j])
	}
	return dataTransfer.files
}

const isMobile = {
	android(){
		return navigator.userAgent.match(/Android/i) == null ? false : true;
	},
	ios(){
		return navigator.userAgent.match(/iPhone|iPad|iPod/i) == null ? false : true;
	},
	any(){
		return (isMobile.android() || isMobile.ios());
	}
}

// 메타태그 추가, 삭제 

const pushDynamicMetaTag = (payload) =>{
	const headTag = document.querySelector('head')
	const styleSheetLinkTag = document.querySelector("[rel='stylesheet']")
	
	for(let i = 0; i<payload.length; i++){
		const createdMetaTag = document.createElement('meta')
		createdMetaTag.name = payload[i].name
		createdMetaTag.content = payload[i].content
		headTag.insertBefore(createdMetaTag, styleSheetLinkTag)
	}
} 
const blockBodyScrollEvents = () => {
	document.body.style.overflow = "hidden";
	document.body.style.top = `-${window.scrollY}px`;
	document.body.style.left = "0";
	document.body.style.position = "fixed";
}

const releaseBodyScroll = () =>{
	const scrollY = document.body.style.top;
	document.body.style.overflow = "unset";
	document.body.style.position = "relative";
	document.body.style.top = "0";
	document.body.style.left = "0";
	window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
}
const gtmCustomEvent = (eventName) =>{
	try{
		//구글 태그매니저에 정의한 것과 동일하게 작성합니다. 
		//이벤트 이름(영문 캐멀 케이스) : 이벤트 한글이름(GTM 태그 및 트리거 이름)
		const attachIsMobile = isMobile.any() ? 'mo_' : 'pc_'
		window.dataLayer.push({
			'event': `${attachIsMobile}${eventName}`,
		});
	}
	catch(e){
		console.log(e)
	}
}

const convertVirtualAccountExpireDateTime = (dateTimeValue) =>{
	try{
		const expireDateTime = new Date(dateTimeValue)
		const setPadStart = (value) => String(value).padStart(2, "0")
		const month = setPadStart(expireDateTime.getMonth() + 1)
		const day = setPadStart(expireDateTime.getDate())
		const hour = setPadStart(expireDateTime.getHours())
		const min = setPadStart(expireDateTime.getMinutes())
		return `${month}월 ${day}일 ${hour}:${min}`
	}
	catch(e){
		return `00월 00일 00:00`
	}
}
const setDelay = timeToDelay => new Promise(resolve => setTimeout(resolve, timeToDelay))
export {
	formatDate, 
	popupAlignedCenter, 
	getDataFiles, 
	getRemovedFileResult, 
	isMobile, 
	pushDynamicMetaTag, 
	blockBodyScrollEvents, 
	releaseBodyScroll, 
	gtmCustomEvent,
	convertVirtualAccountExpireDateTime,
	setDelay
}