<template>
	<!--전체 점검용 안내 푸터-->
	<footer>
		<div class="footer__all">
			<div class="footer__wrapper">
				<router-link class="footer__logo" to="/">
					<img src="@/assets/img/logo_w_new@2x.png" alt="logo"/>
				</router-link>
				<div class="footer__bottom">
					상호명 : ㈜인포바인 | 대표이사 : 권성준<br />
					사업자등록번호 : <a onclick="return false">106-81-86362</a> <br />
					통신판매업신고번호:2020-서울영등포-0275<br />
					주소 : 서울특별시 영등포구 여의대로 24, 40층<br />
					고객센터 : <a onclick="return false">070-7549-4891</a><br /><br />
					© INFOVINE Korea Corporation All Rights Reserved.
				</div>
			</div>
		</div>
	</footer>
</template>

<script setup>
</script>

<style scoped>
footer {
	position: relative;
	width: 100%;
}
.footer__copyright-only {
	border-top: 1px solid #d8d8d8;
	height: 140px;
	font-size: 12px;
	text-align: center;
	line-height: 140px;
	width: 100%;
	color: #888;
	background: #f8f9fa;
}

.footer__all {
	width: 100%;
	background: #333;
}

.footer__all.onshopdetail {
	height: 340px;
}
.footer__wrapper {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
	padding: 25px;
}
.footer__all .footer__logo {
	display: block;
	width: 133px;
	height: 22px;
}

.footer__all .footer__logo img {
	width: 100%;
	object-fit: contain;
}

.footer__all .footer__links {
	display: inline-block;
	margin-left: 0px;
}

.footer__all .footer__links a,
.footer__all .footer__links button {
	width: auto;
	color: #fff;
	font-size: 13px;
	line-height: 0;
	height: auto;
	border: none;
	background: none;
	display: inline-block;
}

.footer__all span {
	display: inline-block;
	vertical-align: middle;
	color: #fff;
	margin: 0 4px;
	font-size: 10px;
	margin-top: -2px;
}

.footer__all .footer__bottom {
	color: #fff;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0px;
	font-weight: normal;
	margin-top: 0px;
}
.footer__all .footer__bottom span, .footer__all .footer__bottom a{
	text-decoration:none;
	color:inherit;
	font-size: 12px;
}
@media screen and (min-width: 760px) {
	footer {
		position: relative;
		width: 100%;
	}
	.footer__all {
		display: flex;
	}
	.footer__wrapper {
		display: block;
		width: 640px;
		margin: 0 auto;
		padding: 0;
		position: relative;
		height: 200px;
	}

	.footer__all .footer__logo {
		width: 200px;
		display: block;
		position: absolute;
		left: 60px;
		top: 60px;
	}

	.footer__all .footer__links {
		display: block;
		width: 318px;
		position: absolute;
		top: 18px;
		right: 0;
		font-size: 12px;
	}

	.footer__all .footer__bottom {
		width: 318px;
		position: absolute;
		top: 50px;
		right: 0;
		font-size: 12px;
		letter-spacing: 0;
		margin: 0;
	}
}
</style>