import axios from 'axios' 
import {blockBodyScrollEvents, releaseBodyScroll} from '@/util/global'
const apiURL = process.env.VUE_APP_API 

export const inquiry = {
	namespaced:true,
	state:{
		isInquiryPopupActived:false,
		askid:null,
		gameid:null,
		gameName:null,
		itemName:null,
		methodid:null,
		orderid:null,
		reqParams:{
			methodid:null,
			gameid:null,
			itemid:null,
			orderid:null
		},
		typeSelectUI:null, //문의 유형 UI
		productInfoUI:null, //상품명 UI
		gameSelectUI:null,
		confirmEvent:null
	},
	mutations:{
		setReqParams(state, payload){
			state.gameid = payload.gameid || null
			state.itemid = payload.itemid || null
			state.methodid = payload.methodid || null
			state.orderid = payload.orderid || null
		},
		toggleIsInquiryPopupActived(state, boolean){
			if(boolean){
				blockBodyScrollEvents()
			}
			else { // 닫으면 초기화
				state.gameName = null
				state.itemName = null
				state.methodid = null
				state.askid = null
				state.gameid = null,
				state.itemid = null,
				state.orderid = null
				state.typeSelectUI = null
				state.productInfoUI = null
				state.gameSelectUI = null
				state.confirmEvent = null
				releaseBodyScroll()
			}
			state.isInquiryPopupActived = boolean
		},
		completeInquiry(state){
			state.gameName = null
			state.itemName = null
			state.methodid = null
			state.askid = null
			state.gameid = null,
			state.itemid = null,
			state.orderid = null
			state.typeSelectUI = null
			state.productInfoUI = null
			state.gameSelectUI = null
			state.confirmEvent = null
			state.isInquiryPopupActived = false
		},
		setAskID(state, id){
			state.askid = id
		},
		setMethodID(state, id){
			state.methodid = id
		},
		setInquiryTypeUI(state, payload){
			state.typeSelectUI = payload
		},
		setProductInfoUI(state, payload){
			state.productInfoUI = payload
		},
		setInquiryData(state, payload){
			state.askid = payload.askid || null
			state.gameid = payload.gameid || null
			state.itemid = payload.itemid || null
			state.methodid = payload.methodid || null
			state.orderid = payload.orderid || null
			state.gameSelectUI = {
				data:[{
					name:payload.gameInfo.name,
					value:payload.gameInfo.gameid
				}]
			}
			if(state.methodid === 2){
				state.productInfoUI = [{name:'상품명', value:payload.itemInfo.name}]
			}
			else if(state.methodid === 3){
				state.productInfoUI = [
					{name:'상품명', value:payload.itemInfo.name},
					{name:'주문번호', value:payload.orderid}
				]
			}
			state.typeSelectUI = payload.askType
		},
	},
	actions:{
		async reqInquiry({commit, dispatch}, payload){
			try{
				const response = await axios.post(`${apiURL}/api/v1/mypage/ask/req`, payload)
				if(response.data.code !== 'S0000'){
					dispatch('resultCodeProcessor/setSelectedPopupByCode', response.data.code, {root:true})
					return
				}
				else {
					commit('setInquiryData', {...response.data.data, ...payload}) // 필요한 ui업데이트
					commit('toggleIsInquiryPopupActived', true)
				}
			}
			catch(e){
				console.log(e)
			}
		},
		async registerInquiry(_, payload){
			try{
				const response = await axios.post(`${apiURL}/api/v1/mypage/ask/reg`, payload)
				return response.data.code
			}
			catch(e){
				console.log(e)
			}
		},
	},
	getters:{
		getIsInquiryPopupActived(state){
			return state.isInquiryPopupActived
		},
		getInquiryRegisterParams(state){
			return state.inquiryRegisterParams
		},
		getAskID(state){
			return state.askid
		},
		getReqParams(state){
			return state.reqParams
		},
		getInquiryData(state){
			return {
				gameName:state.gameName,
				itemName:state.itemName,
				methodid:state.methodid,
				askid:state.askid,
				gameid:state.gameid,
				itemid:state.itemid,
				orderid:state.orderid,
				typeSelectUI:state.typeSelectUI,
				productInfoUI:state.productInfoUI,
				gameSelectUI:state.gameSelectUI
			}
		},
		getConfirmEvent(state){
			return state.confirmEvent
		}
	}
}